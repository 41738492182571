import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { FancySwitch as ReactFancySwitch } from "@omit/react-fancy-switch";
import { useEffect, useRef, useState } from "react";

export default function FancySwitch({
    options,
    value,
    onChange,
    optLabel = "label",
    optValue = "value",
    className = "",
    containerClass = "",
    radioClassName = "",
    dense = false,
    displayLimit = 0,
}) {
    const containerRef = useRef(null);
    const [visibleOptions, setVisibleOptions] = useState([]);
    const [overflowOptions, setOverflowOptions] = useState([]);

    useEffect(() => {
        // Validate if the current value exists in options
        const valueExists = options?.some(opt => opt[optValue] === value);
        if (!valueExists && options?.length > 0) {
            // If value doesn't exist, set to first option
            onChange(options[0][optValue]);
        }
    }, [value, options, optValue, onChange]);

    useEffect(() => {
        const calculateVisibleOptions = () => {
            if (!containerRef.current || !options?.length) return;

            // Find the selected option
            const selectedOption = options.find(opt => opt[optValue] === value);

            // If displayLimit is set and > 0, handle it with selected option prioritization
            if (displayLimit && displayLimit > 0) {
                let visibleOpts = options.slice(0, displayLimit);
                let overflowOpts = options.slice(displayLimit);

                // If selected option is in overflow, swap it with the last visible option
                if (selectedOption && overflowOpts.some(opt => opt[optValue] === value)) {
                    // Find the selected option in the overflow
                    const selectedOverflowIndex = overflowOpts.findIndex(opt => opt[optValue] === value);
                    // Get the last visible option
                    const lastVisibleOption = visibleOpts[visibleOpts.length - 1];

                    // Perform the swap
                    visibleOpts[visibleOpts.length - 1] = overflowOpts[selectedOverflowIndex];
                    overflowOpts[selectedOverflowIndex] = lastVisibleOption;
                }

                setVisibleOptions(visibleOpts);
                setOverflowOptions(overflowOpts);
                return;
            }

            // For dynamic width calculation
            const container = containerRef.current;
            const parentWidth = container.parentElement?.offsetWidth || 0;
            const dropdownButtonWidth = 40;
            const availableWidth = parentWidth - dropdownButtonWidth;

            // Create a temporary element to measure option widths
            const tempElement = document.createElement('div');
            tempElement.style.visibility = 'hidden';
            tempElement.style.position = 'absolute';
            tempElement.className = cn(
                "flex items-center justify-center rounded-full font-medium px-3.5",
                dense ? "text-xs h-7 px-2.5" : "text-sm h-9 px-3.5"
            );
            document.body.appendChild(tempElement);

            let totalWidth = 0;
            let visibleCount = 0;

            // Measure options without prioritizing selected option
            for (let i = 0; i < options.length; i++) {
                tempElement.textContent = options[i][optLabel];
                const optionWidth = tempElement.offsetWidth;
                const widthWithPadding = optionWidth + 4;

                if (totalWidth + widthWithPadding <= availableWidth) {
                    totalWidth += widthWithPadding;
                    visibleCount++;
                } else {
                    break;
                }
            }

            document.body.removeChild(tempElement);

            let visibleOpts = options.slice(0, visibleCount);
            let overflowOpts = options.slice(visibleCount);

            // If selected option is in overflow, swap it with the last visible option
            if (selectedOption && overflowOpts.some(opt => opt[optValue] === value)) {
                const selectedOverflowIndex = overflowOpts.findIndex(opt => opt[optValue] === value);
                const lastVisibleOption = visibleOpts[visibleOpts.length - 1];

                // Perform the swap
                visibleOpts[visibleOpts.length - 1] = overflowOpts[selectedOverflowIndex];
                overflowOpts[selectedOverflowIndex] = lastVisibleOption;
            }

            setVisibleOptions(visibleOpts);
            setOverflowOptions(overflowOpts);
        };

        // Initial calculation
        calculateVisibleOptions();

        // Only add resize observer if displayLimit is not set
        if (!displayLimit) {
            const resizeObserver = new ResizeObserver(calculateVisibleOptions);
            if (containerRef.current) {
                resizeObserver.observe(containerRef.current.parentElement);
            }

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [options, dense, displayLimit, value, optLabel, optValue]);


    const handleOverflowOptionSelect = (option) => {
        onChange(option[optValue]);
    };

    return (
        <div ref={containerRef} className={cn("flex-nowrap flex items-center gap-2", containerClass)}>
            <ReactFancySwitch
                options={visibleOptions}
                value={value}
                onChange={onChange}
                valueKey={optValue}
                labelKey={optLabel}
                className={cn("flex w-fit rounded-full bg-muted",
                    dense ? "text-xs" : "text-sm",
                    className
                )}
                highlighterClassName="bg-primary rounded-full"
                radioClassName={cn(
                    "relative flex text-primary cursor-pointer items-center whitespace-nowrap justify-center rounded-full font-medium transition-colors focus:outline-none data-[checked]:text-primary-foreground",
                    dense ? "h-7 px-2.5" : "h-9 px-3.5",
                    radioClassName
                )}
                highlighterIncludeMargin={true}
                highlighterStyle={{
                    backgroundColor: '#0891b2',
                    borderRadius: dense ? '16px' : '20px'
                }}
            />

            {overflowOptions.length > 0 && (
                <DropdownMenu>
                    <DropdownMenuTrigger className={cn(
                        "inline-flex items-center justify-center rounded-full bg-muted px-2",
                        dense ? "h-7" : "h-9"
                    )}>
                        <span className="flex items-center">
                            <span className="ml-1 text-sm whitespace-nowrap text-[rgb(24, 24, 27)] font-[500]">
                                {overflowOptions.length} More
                            </span>
                            <Icon icon="mdi:chevron-down" />
                        </span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        {overflowOptions.map((option) => (
                            <DropdownMenuItem
                                key={option[optValue]}
                                onClick={() => handleOverflowOptionSelect(option)}
                                className={cn(
                                    "cursor-pointer",
                                    value === option[optValue] && "bg-primary/10"
                                )}
                            >
                                {option[optLabel]}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </div>
    );
}

